@import url("https://use.typekit.net/iyd3oxw.css");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

p {
  font-family: "aktiv-grotesk", sans-serif;
  font-weight: 300;
  font-size: 1.1rem;
  letter-spacing: 0.1rem;
  line-height: 1.3rem;
}

h1,
h2,
h3 {
  font-family: "ivypresto-headline", serif;
  font-weight: 100;
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2.2rem;
}

.button {
  font-family: "aktiv-grotesk", sans-serif;
  font-weight: 300;
  font-size: 1.1rem;
  letter-spacing: 0.1rem;
  line-height: 1.3rem;
  border: none;
  text-align: center;
  background-color: #8a26a0;
  color: #e5d6fc;
  text-decoration: none;
  padding: 1rem 2rem;
  cursor: pointer;
  transition: background 250ms ease-in-out, transform 150ms ease;
}

.button:hover,
.button:focus {
  background: #20123e;
}

.button:focus {
  outline: 1px solid #e5d6fc;
  outline-offset: -4px;
}
