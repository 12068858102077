.grid,
.homepageIntroduction {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0 1rem;
  padding: 4rem 2rem;
}

.headerGradient {
  min-height: 30rem;
}

.homepageHeaderGradient {
  height: 100vh;
  width: 100%;
  background-position: center center;
  background-image: url("HomepageSvgGradient.svg");
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, 1fr);
  padding: 8rem 2rem 0;
  gap: 0 1rem;
}

.featureText {
  font-size: 3rem;
}

.image {
  object-fit: cover;
  min-height: 100%;
  max-width: 100%;
  height: 30rem;
}

.gradientGridAlign {
  display: grid;
  align-items: center;
}

.headerGradientItemA {
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 1;
}

.headerGradientItemB {
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 2;
}

.headerGradientItemC {
  grid-column-start: 2;
  grid-column-end: 4;
  grid-row-start: 3;
}

.headerGradientItemD {
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 4;
}

.gridCentered {
  align-items: center;
  min-height: 40rem;
}

.image,
svg {
  display: block;
}

.displayGrid {
  display: grid;
}

.homepageTitlePadding {
  padding-bottom: 3rem;
}

.buttonMargin {
  margin: 2rem 3rem 2rem;
}

.contentPadding {
  padding-bottom: 1rem;
}

.imagePadding {
  padding-bottom: 3rem;
}

.sectionOnegridPadding {
  padding-left: 2rem;
  padding-right: 2rem;
}

.homepageIntroductionGridItem {
  grid-column-start: 1;
  grid-column-end: 5;
}

.homepageIntroduction {
  background-color: #20123e;
  height: 30rem;
}

.homepageServices {
  background-color: #e8e3d6;
}

.homepageAboutMe {
  background-color: #20123e;
}

.homepageServicesGrid {
  grid-template-rows: repeat(5, auto);
}

.homepageAboutMeGrid {
  grid-template-rows: repeat(4, auto);
}

.textColourCream {
  color: #e8e3d6;
}

.textColourLightPurple {
  color: #e5d6fc;
}

.textColourPurple {
  color: #4a2a93;
}

.textColourDarkPurple {
  color: #20123e;
}

.textAlign {
  text-align: center;
}

.servicesItemA,
.homepageAboutMeItemA {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 1;
}

.servicesItemB,
.homepageAboutMeItemB {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 2;
}

.servicesItemC,
.homepageAboutMeItemC {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 3;
}

.servicesItemD {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 4;
}

.aboutMeButtonItem {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 4;
  margin-top: 3rem;
}

.servicesButtonItem {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 5;
  margin-top: 3rem;
}

@media only screen and (min-width: 50rem) {
  .featureText {
    font-size: 4rem;
  }

  .homepageHeaderGradient {
    grid-template-columns: repeat(8, 1fr);
    padding: 8rem 5rem 0;
  }

  .headerGradientItemA {
    grid-column-start: 1;
    grid-column-end: 8;
    grid-row-start: 1;
  }

  .headerGradientItemB {
    grid-column-start: 5;
    grid-column-end: 8;
    grid-row-start: 2;
  }

  .headerGradientItemC {
    grid-column-start: 2;
    grid-column-end: 8;
    grid-row-start: 3;
  }

  .headerGradientItemD {
    grid-column-start: 5;
    grid-column-end: 8;
    grid-row-start: 4;
  }

  .buttonMargin {
    margin-top: 3rem;
  }

  .homepageTitlePadding {
    padding-bottom: 1rem;
  }

  .homepageSectionOne {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .image {
    object-fit: cover;
    min-height: 100%;
  }

  .textAlign {
    text-align: left;
  }

  .homepageIntroduction {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }

  .sectionOnegridPadding {
    padding-left: 5rem;
    padding-right: 0.5rem;
  }

  .homepageIntroductionGridItem {
    grid-column-start: 1;
    grid-column-end: 4;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 0 1rem;
    padding: 12rem 5rem;
  }

  .homepageServicesGrid {
    grid-template-rows: repeat(6, auto);
  }

  .servicesItemA {
    grid-column-start: 5;
    grid-column-end: 9;
    grid-row-start: 2;
  }

  .servicesItemB {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 7;
  }

  .servicesItemC {
    grid-column-start: 5;
    grid-column-end: 9;
    grid-row-start: 3;
  }

  .servicesItemD {
    grid-column-start: 5;
    grid-column-end: 9;
    grid-row-start: 4;
  }

  .servicesButtonItem {
    grid-column-start: 5;
    grid-column-end: 9;
    grid-row-start: 5;
  }

  .homepageAboutMeItemA {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 2;
  }

  .homepageAboutMeItemB {
    grid-column-start: 5;
    grid-column-end: 9;
    grid-row-start: 1;
    grid-row-end: 6;
  }

  .homepageAboutMeItemC {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 3;
  }

  .aboutMeButtonItem {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 4;
  }

  .servicesTitlePadding,
  .aboutMeTitlePadding {
    padding-top: 0;
  }

  .imagePadding {
    padding-bottom: 0;
  }
}

@media only screen and (min-width: 70rem) {
  .homepageHeaderGradient {
    grid-template-columns: repeat(12, 1fr);
    padding: 8rem 4.375rem 0;
  }

  .headerGradientItemA {
    grid-column-start: 2;
    grid-column-end: 12;
    grid-row-start: 1;
  }

  .headerGradientItemB {
    grid-column-start: 9;
    grid-column-end: 12;
    grid-row-start: 2;
  }

  .headerGradientItemC {
    grid-column-start: 4;
    grid-column-end: 12;
    grid-row-start: 3;
  }

  .headerGradientItemD {
    grid-column-start: 11;
    grid-column-end: 12;
    grid-row-start: 4;
  }

  .homepageIntroduction {
    grid-template-columns: repeat(6, 1fr);
  }

  .sectionOnegridPadding {
    padding-left: 4.375rem;
    padding-right: 0.5rem;
  }

  .homepageIntroductionGridItem {
    grid-column-start: 2;
    grid-column-end: 5;
  }

  .grid {
    grid-template-columns: repeat(12, 1fr);
    padding: 12rem 4.375rem;
  }

  .servicesItemA {
    grid-column-start: 8;
    grid-column-end: 12;
    grid-row-start: 2;
  }

  .servicesItemB {
    grid-column-start: 2;
    grid-column-end: 6;
    grid-row-start: 1;
    grid-row-end: 7;
  }

  .servicesItemC {
    grid-column-start: 8;
    grid-column-end: 12;
    grid-row-start: 3;
  }

  .servicesItemD {
    grid-column-start: 8;
    grid-column-end: 12;
    grid-row-start: 4;
  }

  .servicesButtonItem {
    grid-column-start: 8;
    grid-column-end: 10;
    grid-row-start: 5;
  }

  .homepageAboutMeItemA {
    grid-column-start: 2;
    grid-column-end: 5;
    grid-row-start: 2;
  }

  .homepageAboutMeItemB {
    grid-column-start: 8;
    grid-column-end: 12;
    grid-row-start: 1;
    grid-row-end: 6;
  }

  .homepageAboutMeItemC {
    grid-column-start: 2;
    grid-column-end: 6;
    grid-row-start: 3;
  }

  .aboutMeButtonItem {
    grid-column-start: 2;
    grid-column-end: 4;
    grid-row-start: 4;
    grid-row-end: 5;
  }
}
