.consultationPage {
  background-color: #20123e;
  height: 70rem;
  padding: 8rem 2rem 8rem;
}

div.calendly-inline-widget {
  height: 100% !important;
}

@media only screen and (min-width: 50rem) {
  .consultationPage {
    height: 65rem;
  }
}
