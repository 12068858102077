.servicesTextAlign,
.servicesIntroGridItem {
  text-align: center;
}

.buttonPadding {
  padding-bottom: 2rem;
}

.servicesContentPadding {
  padding-bottom: 3rem;
}

.textColourLightPurple {
  color: #e5d6fc;
}

.servicesGrid {
  grid-template-columns: repeat(4, 1fr);
  gap: 0 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.servicesPadding {
  padding-bottom: 1rem;
}

.servicesIntro {
  display: grid;
  max-height: 40rem;
  background-color: #20123e;
  grid-template-rows: repeat(4, 1fr);
}

.mobileCentring {
  margin: 0 auto;
}

.servicesIntroGridItem {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 3;
  grid-row-end: 4;
}

.services {
  display: grid;
  min-height: 30rem;
  background-color: #e8e3d6;
  grid-template-rows: repeat(2, auto);
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.reikiGridItemA,
.spiritReadingsItemA {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 1;
  grid-row-end: 2;
}

.reikiGridItemB,
.spiritReadingsItemB {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 2;
  grid-row-end: 3;
}

.spiritReadingsItemC {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 3;
}

@media only screen and (min-width: 50rem) {
  .mobileCentring {
    margin: 0;
  }

  .servicesOutro {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }

  .servicesIntro {
    display: grid;
    max-height: 40rem;
    background-color: #20123e;
    grid-template-rows: repeat(4, 1fr);
  }

  .servicesGrid {
    grid-template-columns: repeat(8, 1fr);
    gap: 0 1rem;
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .servicesTextAlign,
  .servicesIntroGridItem {
    text-align: left;
  }

  .servicesIntroGridItem {
    grid-column-start: 2;
    grid-column-end: 7;
    grid-row-start: 3;
    grid-row-end: 4;
  }

  .spiritReadingsItemA {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 5;
  }

  .spiritReadingsItemB {
    grid-column-start: 5;
    grid-column-end: 9;
    grid-row-start: 2;
    grid-row-end: 3;
  }

  .spiritReadingsItemC {
    grid-column-start: 5;
    grid-column-end: 8;
    grid-row-start: 3;
    grid-row-end: 4;
  }
}

@media only screen and (min-width: 70rem) {
  .servicesGrid {
    grid-template-columns: repeat(12, 1fr);
    padding-left: 4.375rem;
    padding-right: 4.375rem;
  }

  .servicesIntroGridItem {
    grid-column-start: 2;
    grid-column-end: 6;
    grid-row-start: 3;
    grid-row-end: 4;
  }

  .spiritReadingsItemA {
    grid-column-start: 2;
    grid-column-end: 6;
    grid-row-start: 1;
    grid-row-end: 6;
  }

  .spiritReadingsItemB {
    grid-column-start: 8;
    grid-column-end: 12;
    grid-row-start: 2;
    grid-row-end: 4;
    padding-top: 0;
  }

  .spiritReadingsItemC {
    grid-column-start: 8;
    grid-column-end: 11;
    grid-row-start: 4;
  }
}
