.footer {
  background: url("HomepageSvgGradient.svg");
}

.mobileGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 0 1rem;
}

.footerGridPadding {
  padding: 2rem;
}

.footerBrand {
  height: 6rem;
}

.textPadding {
  padding-bottom: 0.2rem;
}

.footerContact {
  font-family: "aktiv-grotesk", sans-serif;
  font-weight: 300;
  font-size: 1.1rem;
  letter-spacing: 0.1rem;
  line-height: 1.3rem;
  color: #e5d6fc;
}

.footerGridItemA {
  grid-column-start: 1;
}

.footerGridItemB {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 2;
  text-align: left;
  padding-top: 2rem;
}

.footerGridItemC {
  grid-column-start: 2;
  text-align: right;
}

@media only screen and (min-width: 50rem) {
  .footerGridPadding {
    padding: 2rem 5rem;
  }
}

@media only screen and (min-width: 70rem) {
  .footerGridPadding {
    padding: 2rem 4.375rem;
  }
}
